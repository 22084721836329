import {PatientItem} from "resources/classes/Patient/PatientItem";
import {ICDCodeItem} from "resources/classes/IICDCode";
import {IQuestionnaireList, QuestionnaireService} from "./QuestionnaireService";
import {HttpClient} from "aurelia-http-client";
import {fhirEnums} from "../classes/fhir-enums";
import {FhirService} from "./FhirService";

export class ICDService {
    fhirService: FhirService;
    qList: IQuestionnaireList;
    icdMappingSource: string = undefined;
    static lastPatient: string;
    static _conditions: any[];
    private _icdCodes: ICDCodeItem[] = undefined;
    static lastEvidence: string;

    constructor() {
        this.fhirService = new FhirService();
    }

    public get conditions(): any[] {
        if (!ICDService._conditions) ICDService._conditions = [];
        return ICDService._conditions;
    }

    public getConditionByCode(code: string): any {
        if (!this.conditions) return;
        let existing = this.conditions.find(o => o.code && o.code.coding && o.code.coding[0] && o.code.coding[0].code === code);
        return existing;
    }

    public clear() {
        ICDService._conditions = undefined;
        this._icdCodes = undefined;
        ICDService.lastEvidence = undefined;
    }

    /**
     * Loads all ICD-Codes from the icd_mapping.json file and stores the *source* to icdMappingSource for further references
     * @param patient the Patient to prepare the list fpr
     */
    public async getICDCodes(patient: PatientItem): Promise<ICDCodeItem[]> {
        if (!this.qList) this.qList = await QuestionnaireService.GetQuestionnaireIds();

        if (!this.icdMappingSource) {
            let result = await new HttpClient().get('config/icd_mapping.json');
            this.icdMappingSource = result.response;
        }

        //#region load ICD mappings if not existent
        if (!this._icdCodes || this._icdCodes.length === 0) {
            let url = 'config/icd_mapping.json';
            try {
                this._icdCodes = JSON.parse(this.icdMappingSource);
                this._icdCodes = this._icdCodes.filter(o => o.icd && o.icd.toUpperCase() !== "_STRUCTURE_INFO_");

                for (let i = 0; i < this._icdCodes.length; i++) {
                    this._icdCodes[i] = new ICDCodeItem(this._icdCodes[i], patient, this.qList);
                }

                /* for (const icd of this._icdCodes) {
                    let condition = icd.toCondition();
                    condition.id = NitTools.Uid();
                    this.conditions.push(condition);
                    // await this.createCondition(icd.toCondition());
                } */
            } catch (e) {
                let message = `Loading ICD Codes from file "${url}" failed`;
                if (e && e.message) {
                    message += "\n" + e.message;
                }

                console.warn(message);
                console.warn(e);

                return Promise.reject(message);
            }
        }
        //#endregion

        let result: ICDCodeItem[] = [];

        //#region process icd codes
        for (const item of this._icdCodes) {
            let icd: ICDCodeItem = new ICDCodeItem(item, patient, this.qList);
            if (!icd.isValid) {
                console.warn( `Invalid ICD-Item specified! Reasons:\n${icd.invalidReason}`, icd);
                continue;
            }

            //if (typeof result.find((o: ICDCodeItem) => o.icd === icd.icd) === "undefined")
            //   result.push(icd);
            result.push(icd);
        }
        //#endregion

        return Promise.resolve(result);
    }

    public async calculateICDCodes(patient: PatientItem, assessment: any): Promise<ICDCodeItem[]> {
        let codes = await this.getICDCodes(patient);
        let result: ICDCodeItem[] = [];
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, this.qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        let iso = QuestionnaireService.GetLatestResponseOfType(patient, this.qList.QIsolationId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

        for (let i = 0; i < codes.length; i++) {
            let icd: ICDCodeItem = new ICDCodeItem(codes[i], patient, this.qList);
            icd.assessment = assessment;
            icd.anamnesis = anamnesis;
            icd.isolation = iso;

            if (icd.test())
                result.push(icd);
        }

        return Promise.resolve(result);
    }

    public check(condition: any) {
        return typeof this.conditions.find(o => o.code && o.code.coding && o.code.coding[0] && o.code.coding[0].code === condition.code.coding[0].code) !== "undefined";
    }

    public async createCondition(condition: any): Promise<any> {
        let evidence = condition.evidence[0].detail[0].reference;
        if (ICDService.lastEvidence !== evidence) {
            await this.fetch(condition.evidence[0].detail[0].reference);
            ICDService.lastEvidence = evidence;
        }

        let existing: any = undefined;
        if (condition.code && condition.code.coding && condition.code.coding[0] && condition.code.coding[0].code) {
            existing = this.conditions.find(o => o.code && o.code.coding && o.code.coding[0] && o.code.coding[0].code === condition.code.coding[0].code);
            if (existing) {
                // console.info(`ICD-Condition with code "${condition.code}" already exists`, condition);
                return Promise.resolve(existing);
            }
        }

        existing = <any>await this.fhirService.create(condition);
        // console.info(`ICD-Condition created`, existing);
        this.conditions.push(existing);

        return Promise.resolve(existing);
    }


    public async fetch(evidence: string) {
        if (evidence.indexOf('/') > -1) evidence = evidence.split('/')[1];
        if (ICDService.lastEvidence !== evidence || !this.conditions || this.conditions.length === 0) {
            const existing : any[] = await this.fhirService.fetch(`Condition?evidence-detail=${evidence}&clinical-status=active,recurrence,remission`);
            ICDService._conditions = [];
            
            for (const ex of existing.filter(c => c.identifier)) {
                if (typeof ex.identifier.find(o=>o.system?.indexOf('icd-item') > -1) !== 'undefined') {
                    ICDService._conditions.push(ex);
                }
            }

            ICDService.lastEvidence = evidence;
        }
        
        return this.conditions;
    }
}
